.modal {
    font-size: 12px;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray; 
    font-size: 24px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
    vertical-align: top;
    display: inline-block;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
/*  
@media screen and (min-width: 992px) { 
    .modal.modal-content {
      max-width: 200px;
      max-height: 80%;
  }
}
*/  
