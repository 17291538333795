@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

body,
input,
textarea,
button {
  font-family: "Nunito Sans", sans-serif;
}
audio,
button:focus {
    outline: none;
}

::-webkit-scrollbar {
    display: none;
}

.modal {
    font-size: 12px;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray; 
    font-size: 24px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
    vertical-align: top;
    display: inline-block;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
/*  
@media screen and (min-width: 992px) { 
    .modal.modal-content {
      max-width: 200px;
      max-height: 80%;
  }
}
*/  

.imodal {
  font-size: 12px;
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 400px;
  max-height: 400px;
}
.imodal > .header {
  width: auto;
  border-bottom: 1px solid gray; 
  font-size: 24px;
  text-align: center;
  padding: 5px;
}
.imodal > .content {
  width: auto;
  padding: 10px 5px;
  text-align: center;
  vertical-align: top;  
}
.imodal > .actions {
  width: auto;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.imodal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

  .amodal {
    font-size: 12px;
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 400px;
    max-height: 400px;
  }
  .amodal > .header {
    width: auto;
    border-bottom: 1px solid gray; 
    font-size: 24px;
    text-align: center;
    padding: 5px;
  }
  .amodal > .content {
    width: auto;
    padding: 10px 5px;
    text-align: center;
    vertical-align: top;
  }
  .amodal > .actions {
    width: auto;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .amodal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

.toBannerButton {
    margin: 6px;
}

.toBannerButton:focus {
    /*
    font-size: 22px;
    font-weight: 600;
    */
    margin: 6px;
    background-color: #ff9700;
    color:honeydew;
}
* {
    overflow-anchor: none;
}
.atozcontent {
    background-color: lightgrey;
    width: 90%;
    padding: 5px;
    margin: 10px 30px 0px 30px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* white-space: pre-line; */
}

.atozcontent:hover {
    opacity: 0.8;
}

.atoztitle {
    width: 100%;
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
}

.atoztitle:hover {
    cursor: pointer;
}

.atozlist {
    list-style-type: circle;
    display: inline-block;
    position: relative;
}

.atozbutton {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #f9f9f9;
    border: none;
    color: black;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonALL {
    width: 60px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #ff9700; 
    border: none;
    color:honeydew;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonALL:hover {
    cursor: pointer;
}

.atozbuttonALL2 {
    width: 60px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #f9f9f9;
    border: none;
    color: black;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonALL2:hover {
    cursor: pointer;
    width: 60px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #ff9700; 
    border: none;
    color:honeydew;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonSEL {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #ff9700; 
    border: none;
    color:honeydew;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonEMP {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border: none;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbutton:hover {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    margin: 2px;
    cursor: pointer;
    background-color: #ff9700;
    color:honeydew;
    position: relative;
}

.atozbutton:focus {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    margin: 2px;
    background-color: #ff9700;
    color:honeydew;
    position: relative;
}
/*
@media screen and (min-width 768px) and (max-width 1024px) {
    margin:4px;
    font-size:20px;
} 
@media screen and (max-width 768px) {
    margin:2px;
    font-size:18px;
} 
*/
