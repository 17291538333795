.imodal {
  font-size: 12px;
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 400px;
  max-height: 400px;
}
.imodal > .header {
  width: auto;
  border-bottom: 1px solid gray; 
  font-size: 24px;
  text-align: center;
  padding: 5px;
}
.imodal > .content {
  width: auto;
  padding: 10px 5px;
  text-align: center;
  vertical-align: top;  
}
.imodal > .actions {
  width: auto;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.imodal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
