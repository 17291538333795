.atozcontent {
    background-color: lightgrey;
    width: 90%;
    padding: 5px;
    margin: 10px 30px 0px 30px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* white-space: pre-line; */
}

.atozcontent:hover {
    opacity: 0.8;
}

.atoztitle {
    width: 100%;
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
}

.atoztitle:hover {
    cursor: pointer;
}

.atozlist {
    list-style-type: circle;
    display: inline-block;
    position: relative;
}

.atozbutton {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #f9f9f9;
    border: none;
    color: black;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonALL {
    width: 60px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #ff9700; 
    border: none;
    color:honeydew;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonALL:hover {
    cursor: pointer;
}

.atozbuttonALL2 {
    width: 60px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #f9f9f9;
    border: none;
    color: black;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonALL2:hover {
    cursor: pointer;
    width: 60px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #ff9700; 
    border: none;
    color:honeydew;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonSEL {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #ff9700; 
    border: none;
    color:honeydew;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbuttonEMP {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border: none;
    padding: 6px;
    margin: 2px;
    position: relative;
}

.atozbutton:hover {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    margin: 2px;
    cursor: pointer;
    background-color: #ff9700;
    color:honeydew;
    position: relative;
}

.atozbutton:focus {
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    margin: 2px;
    background-color: #ff9700;
    color:honeydew;
    position: relative;
}
/*
@media screen and (min-width 768px) and (max-width 1024px) {
    margin:4px;
    font-size:20px;
} 
@media screen and (max-width 768px) {
    margin:2px;
    font-size:18px;
} 
*/