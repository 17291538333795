.toBannerButton {
    margin: 6px;
}

.toBannerButton:focus {
    /*
    font-size: 22px;
    font-weight: 600;
    */
    margin: 6px;
    background-color: #ff9700;
    color:honeydew;
}