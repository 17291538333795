@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body,
input,
textarea,
button {
  font-family: "Nunito Sans", sans-serif;
}
audio,
button:focus {
    outline: none;
}

::-webkit-scrollbar {
    display: none;
}
